@import '~bootstrap/scss/bootstrap';
@import './variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-content {
  min-height: calc(100vh - 290px);
}

.btn svg {
  position: relative;
  top: -2px;
}
.btn-sm svg {
  top: -1px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $base-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdw-editor-main {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  height: 80% !important;
}

.card-preview {
  img {
    max-width: 100%;
  }
}

.card-preview-face.ql-editor {
  padding: 5px 0;
}

.add-cards-from-other-card-sets-modal {
  .accordion-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card-preview-selectable {
      width: 48%;
    }
  }
}

.sort-buttons {
  display: flex;
  justify-content: space-between;
}

.drill-run-progress {
  display: flex;
  height: 20px;
  border-radius: 5px;

  > * {
    flex: 1;
    background: #e9ecef;
    border: 1px solid #ced4da;
  }
  .numbers {
    text-align: center;
    background: transparent;
    border: none;
  }
  .correct {
    background: #198754;
  }
  .wrong {
    background: #dc3545;
  }
}

.ql-editor {
  font-size: 20px;
}

/* add a border and a shadow to the .drill-run-question container to make it look like a card */
.drill-run-question {

  .drill-run-card {
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    .side {
      text-align: center;
      color: #9e9e9e;
      font-size: 12px;
      text-transform: uppercase;
    }
    .drill-run-question-content {
      min-height: 200px;
    }
  }
}

.drill-run-card {
  transition: transform 0.3s;
  &.front {
    transform: rotateY(360deg);
  }
}
.switching {
  .drill-run-card {
    transition: none;
    visibility: hidden;
  }
}

.switching-overlay {
  opacity: 0;
  background: white;
}
.switching {
  position: relative;
  .switching-overlay {
    border-radius: 10px;
    transition: all 0.5s;
    opacity: 1;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
}



/* twitter bootstrap media query for everything bigger than mobile */
@media (min-width: 480px) {
  .recursive-nav-dropdown {
    > .dropdown-menu {
      .dropdown-menu {
        position: relative;
        left: 1rem;
      }
    }
    .nav-item {
      .dropdown-toggle {
        padding-left: 1rem;
      }
    }
  }
}
@media (max-width: 479px) {
  .recursive-nav-dropdown {
    > .dropdown-menu {
      padding-left: 0.5rem;
      .dropdown-menu {
        padding-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.workspace-card-sets {
  .chevron {
    cursor: pointer;
  }
}

.expand-toggle {
  position: relative;
  top: -0.1rem;
}

.expand-toggle-filler {
  svg {
    opacity: 0;
  }
}